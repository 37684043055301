import React from "react"
import Layout from "../components/layout"

import * as styles from "./karriere.module.scss";
import { StaticImage } from "gatsby-plugin-image"

const BueroPage = () => {

  return <Layout apply={"Bürokaufmann/frau"}>

    <div className={styles.carrerPage}>



      <div
        className={styles.introImage}>
      <StaticImage
        src="../images/schnitzel.jpg"
        width={500}
        quality={85}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Schnitzel" />

        <p>Handel & Verkauf von Lebensmittel,<br /> Herstellung von Schnitzel & Cordon Bleu</p>

      </div>


        <div>
          <h1>Verkäufer/in</h1>
          <h3>18 Stunden <i>oder</i> 38,5 Stunden</h3>
        </div>


      <h2>Anforderungen</h2>
      <ul>
        <li>Ausgezeichnete Deutsch-Kenntnisse in Wort und Schrift</li>
        <li>gute Englischkenntnisse in Wort und Schrift</li>
        <li>Teamfähigkeit</li>
        <li>Zuverlässigkeit</li>
        <li>Selbstständige Arbeitsweise mit rascher Auffassungsgabe und Eigenverantwortung</li>
      </ul>

      <h2>Wir bieten</h2>

      <ul>
        <li>Ein <b>familiäres Arbeitsklima</b> in einem innovativen Vorzeigebetrieb</li>
        <li>Die <b>Sicherheit</b> einer langfristigen Anstellung in einem wachsenden Unternehmen</li>
        <li>angenehmes Arbeitsumfeld in einem freundlichen Team</li>
      </ul>

      <p className={styles.gehalt + " mt-5"}>
        Angaben des Unternehmens gemäß Gleichbehandlungsgesetz: EUR 1.876,- brutto
        auf Basis Vollzeit/38,5 Wochenstunden. Bzw. EUR 877,- Brutto.
      </p>

      <p className={styles.gehaltAdd + " mt-2"}>
        Eine Überzahlung ist möglich und abhängig von Ihrer bisherigen Berufserfahrung und Qualifikation.
      </p>

    </div>

  </Layout>

}

export default BueroPage;
